import React, { useState } from "react";
import "./../css/ingredientsList.css";

export let tacoIngredients 

export const TacoIngredients = () => {
  const [proteina, setProteina] = useState(null);
  const [salsa, setSalsa] = useState(null);
  //const [contorno, setContorno] = useState(null);

  tacoIngredients = [proteina, salsa, ""];
  
  // Uncoment this when controno is added
  //tacoIngredients = [proteina, salsa, contorno];

  const handleIngredientClick = (section, ingredient) => {
    switch (section) {
      case 'proteina':
        if (proteina === ingredient) {
          setProteina(null); // Deselect if already selected
        } else {
          setProteina(ingredient);
        }
        break;
      case 'salsa':
        if (salsa === ingredient) {
          setSalsa(null); // Deselect if already selected
        } else {
          setSalsa(ingredient);
        }
        break;
      // case 'contorno':
      //   if (contorno === ingredient) {
      //     setContorno(null); // Deselect if already selected
      //   } else {
      //     setContorno(ingredient);
      //   }
      //   break;
      default:
        break;
    }
  };


  function color(selectedIngredient) {
    return selectedIngredient ? { color: 'lightgreen' } : { textDecoration: 'line-through', color: 'red' }
  }

  return (
    <div className="list-ingredients-list">
        <h1 style={{fontSize: '70px'}} >Scegli proteina</h1>
        <label className="select-container" style={{ marginTop: '20px' }}>
          <span style={ color(proteina === 'Carne di Pollo') }>Carne di Pollo</span>
          <input type="checkbox" checked={ proteina === 'Carne di Pollo' } onClick={ () => handleIngredientClick('proteina', 'Carne di Pollo') } style={ color(proteina === 'Carne di Pollo') } />
          <span className="select-checkmark"></span>
        </label>
        <label className="select-container">
          <span style={ color(proteina === 'Carne di Vitello') }>Carne di Vitello</span>
          <input type="checkbox" checked={ proteina === 'Carne di Vitello' } onClick={ () => handleIngredientClick('proteina', 'Carne di Vitello') } style={ color(proteina === 'Carne di Vitello') } />
          <span className="select-checkmark"></span>
        </label>
        <label className="select-container">
          <span style={ color(proteina === 'Falafel') }>Falafel</span>
          <input type="checkbox" checked={ proteina === 'Falafel' } onClick={ () => handleIngredientClick('proteina', 'Falafel') } style={ color(proteina === 'Falafel') } />
          <span className="select-checkmark"></span>
        </label>
        <h1 style={{fontSize: '70px'}}>Scegli salsa</h1>
        <br />
        <label className="select-container">
          <span style={ color(salsa === 'Salsa rosa') }>Salsa rosa</span>
          <input type="checkbox" checked={ salsa === 'Salsa rosa' } onClick={ () => handleIngredientClick('salsa', 'Salsa rosa') } style={ color(salsa === 'Salsa rosa') } />
          <span className="select-checkmark"></span>
        </label>
        {/* <h1 style={{fontSize: '55px'}}>Scegli controno</h1>
        <br />
        <label className="select-container">
          <span style={ color(contorno === 'Patate') }>Patate</span>
          <input type="checkbox" checked={ contorno === 'Patate' } onClick={ () => handleIngredientClick('contorno', 'Patate') } style={ color(contorno === 'Patate') } />
          <span className="select-checkmark"></span>
        </label>
        <label className="select-container">
          <span style={ color(contorno === 'Anelli di cipolla') }>Anelli di cipolla</span>
          <input type="checkbox" checked={ contorno === 'Anelli di cipolla' } onClick={ () => handleIngredientClick('contorno', 'Anelli di cipolla') } style={ color(contorno === 'Anelli di cipolla') } />
          <span className="select-checkmark"></span>
        </label> */}
    </div>
  )
};
