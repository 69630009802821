import { React, useState, useEffect } from "react"
import logo from "./../images/logo.png"
import { Link } from "react-router-dom"
import "./../css/home.css"

export const Home = () => {

  //const [data, setData] = useState([])
  const [delivery, setDelivery] = useState()
  const [address, setAddress] = useState()

  async function getData(){
    const obj = JSON.parse(sessionStorage.getItem("sede_selezionata"));

    if (obj === null || obj === undefined) {
      window.location.assign("/");
      return;
    }

    const negozio = obj.negozio;

    setDelivery(obj.delivery)
    setAddress(obj.indirizzo)
    
    const response = await fetch(process.env.REACT_APP_PRODUCTS_ENDPOINT + `?availability=false&shop=${negozio}`);


    if (response.redirected === true) {
      window.location.assign(response.url);
    }

    const json = await response.json();

    
    return json.products;

  }

  useEffect(() => {

    const fetchData = async () => {
      const data = await getData();
      //setData(data);
      sessionStorage.setItem("unavaibleProducts",JSON.stringify(data))
    }

    fetchData();
  
  }, []);

  return (
    <div className="bg">
      <div className="logo">
        <img src={ logo } alt="logo.png" className="logo-image" />
      </div>
      <div className="button-container">
        <Link to="/order" className="text-decoration__none">
          <button className="button">RITIRO</button>
          <button className="button button-cell">RITIRO</button>
        </Link>
        <Link to="/order" className="text-decoration__none">
          <button className="button" disabled={!delivery} onClick={() => sessionStorage.setItem("isDelivery", true)}>CONSEGNA A DOMICILIO</button>
          <button className="button button-cell" style={{marginTop: '100px'}} disabled={!delivery} onClick={() => sessionStorage.setItem("isDelivery", true)}>DOMICILIO</button>
        </Link>
      </div>
      <footer>
        <p>{address}</p>
      </footer>
    </div>
  )
}