import "./App.css"

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import { Home } from "./pages/Home"
import { SingleProduct } from "./pages/SingleProduct"
import { OrderProduct } from "./pages/OrderProduct"
import { Order } from "./pages/Order"
import { Cart } from "./pages/Cart"
import { OrderNumber } from "./pages/OrderNumber"
import { Error } from "./pages/Error"
import { Negozi } from "./pages/Negozi"
import { MenuType } from "./pages/MenuType"

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            {/*<Home />*/}
            <Negozi />
          </Route>
          <Route path="/homepage">
            <Home />
          </Route>
          <Route path="/order/:type/:product">
            <SingleProduct />
          </Route>
          <Route path="/order/:type">
            <OrderProduct />
          </Route>
          <Route path="/menutype">
            <MenuType />
          </Route>
          <Route path="/order">
            <Order />
          </Route>
          <Route path="/cart">
            <Cart />
          </Route>
          <Route path="/number">
            <OrderNumber />
          </Route>
          <Route path="/error">
            <Error />
          </Route>
        </Switch>
      </Router>
    </>
  )
}

export default App;
