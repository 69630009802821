import { React } from "react"
import { Link } from "react-router-dom"
import "./../css/orderNumber.css"

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const orderID = urlParams.get('orderID');

export const OrderNumber = () => {
/*

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "/";
    }, 4000);
    return () => clearTimeout(timer);
  }, []);
*/

  return (
    <>
      <Link to="/" className="text-decoration__none">
        <div className="bg-number">
          <p className="top-number">Il tuo numero d'ordine è:</p>
          <p className="center-number">{ orderID }</p>
        </div>
      </Link>
    </>
  )
}