export let i = 0

export function genearteKey() {
    i++
}

export function emptyLocalStorage() {
    localStorage.clear()
}

export function resetKey() {
    i=0
}

export function getAllElements() {
    var localStorageItems = []
    for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);
        localStorageItems.push(JSON.parse(localStorage.getItem(key)));  
    }

    return localStorageItems
}

export function getTotalAmount(){
    var total = 0

    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while ( i-- ) {
        values.push(JSON.parse(localStorage.getItem(keys[i])));
    }

    for (let i = 0; i < values.length; i++) {
        if (values[i].tipo ==="menu") {
            if ((values[i].bibita.includes("RedBull") || values[i].bibita.includes("ml")) && !values[i].bibita.includes("Acqua") )  {
               total += 0.50 * parseFloat(values[i].quantita)
            }
        }
        if (values[i].hasOwnProperty('extra')) {
            if (values[i].extra.formaggio){
                total += 1 * parseFloat(values[i].quantita)
            }
            if (values[i].extra.patate) {
                total += 1 * parseFloat(values[i].quantita)
            }
            if (values[i].extra.verdura) {
                total += 1 * parseFloat(values[i].quantita)
            }
        }
        if (values[i].quantita>1) {
            total += parseFloat(values[i].price) * parseFloat(values[i].quantita)
        }else
            total += parseFloat(values[i].price)
    }

    return total
}

export function isEmpty() {
    var isEmpty
    if (localStorage.length===0) {
        isEmpty = true
    }else{
        isEmpty = false
    }
    return isEmpty;
}