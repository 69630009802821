import { React, useState } from "react"
import { getAllElements, getTotalAmount } from "../javascript/localStorageFunctions";
import { CartProduct } from "./CartProduct"

export const CartProductList = ({totalAmount}) => {
  
  const [products, setProducts] = useState(getAllElements())

  function deleteProduct(index) {
    updateTotal()
    localStorage.removeItem(index)
    setProducts(getAllElements())
  }

  function updateTotal() {
    totalAmount(getTotalAmount())
  }

  return (
    <div>
      { products.map((item, index) => (
        <CartProduct key={index} item={item} deleteElem={deleteProduct} updateTOT={updateTotal}/>
      ))}
	</div>
  )
}