import { React, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { CartProductList } from "../components/CartProductList"
import { resetKey, emptyLocalStorage, getAllElements, isEmpty } from "../javascript/localStorageFunctions"
import "./../css/cart.css"
import { getTotalAmount } from "../javascript/localStorageFunctions";
import IdleTimerContainer from "../components/IdleTimerContainer"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import dayjs from 'dayjs';

let timestamp;

let minutes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
  18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
  33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
  48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,]

let minutes2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
  18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
  33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
  48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,]

let hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
let nonWorkingHours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 16, 23];
//let disabledHours = [];
let currentHour = dayjs().hour();

export const Cart = () => {
  let order = getAllElements()
  const [confirm, setConfirm] = useState(false);
  const [tot, setTot] = useState(getTotalAmount());
  const [disabled, setDisabled] = useState(false);
  const [disableProceed, setDisableProceed] = useState(true);
  const [waitTime, setWaitTime] = useState([]);
  const [pastHours, setPastHours] = useState([]);
  const [hideMinutes, setHideMinutes] = useState(true);
  const [hideFutureMinutes, setHideFutureMinutes] = useState(true);
  //const [currentHour, setCurrentHour] = useState(17);
  const [disabledHours, setDisabledHours] = useState([])
  const [showHours, setShowHours] = useState([])
  const [showMinutes, setShowMinutes] = useState([])
  const [isDelivery, setDelivery] = useState()

  const [oraRitiro, setOraRitiro] = useState()
  const [minRitiro, setMinRitiro] = useState()

  const [cittaConsegna, setCittaConsegna] = useState()
  const [via, setVia] = useState()
  const [civico, setCivico] = useState()
  const [cities, setCities] = useState([])



  const success = () => toast.success('Stiamo elaborando il tuo ordine!', {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const warn = (str) => toast.warn(str, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  async function getDisabledMinutes(tot, delivery) {

    let currentMinute = dayjs().minute();
    //const tot = await getTotalWaitTime();
    let totalWaitTime = tot + currentMinute;

    if (delivery) {
      totalWaitTime = totalWaitTime + 10;
    }

    // if totalMinutes exceeds 59 I need to disable the next hour
    // and remove 59 from the current total to add to wait time 

    if (totalWaitTime > 59) {
      // while total wait time > 59 add one hour to the current one

      const hs = Math.floor(totalWaitTime / 60);
      const ms = totalWaitTime % 60;

      // fix this 
      //setCurrentHour(currCount => currCount + hs);
      currentHour = currentHour + hs
      if (nonWorkingHours.includes(currentHour)) {
        currentHour++
      }
      //setCurrentHour(hs);

      //let newWaitMinutes = totalWaitTime-59;

      for (let i = 0; i <= ms; i++) {
        waitTime[i] = i;
      }
      //totalWaitTime = newWaitMinutes;
    } else {
      for (let i = 0; i <= totalWaitTime; i++) {
        waitTime[i] = i;
      }
    }

    //from minutes array remove disabled minutes
    for (let i = 0; i < minutes.length; i++) {
      for (let j = 0; j < waitTime.length; j++) {
        if (minutes[i] === waitTime[j]) {
          minutes[i] = null;
        }
      }
    }

    minutes.forEach(element => {
      if (element !== null) {
        showMinutes.push(element)
      }
    });

    getDisabledHours();
  }

  function getDisabledHours() {
    // currentHour is not updated ++;
    for (let i = 0; i < currentHour; i++) {
      pastHours[i] = i;
    }

    let arr = nonWorkingHours.concat(pastHours);

    // loop through array
    for (let i of arr) {
      if (disabledHours.indexOf(i) === -1) {
        disabledHours.push(i);
      }
    }

    //from hours array remove disabled hours
    for (let i = 0; i < hours.length; i++) {
      for (let j = 0; j < disabledHours.length; j++) {
        if (hours[i] === disabledHours[j]) {
          hours[i] = null;
        }
      }
    }

    hours.forEach(element => {
      if (element !== null) {
        showHours.push(element)
      }
    });
  }

  function handleMinuteChange(e) {
    let selectedMinute = e.target.value;

    if (selectedMinute !== '') {
      setDisableProceed(false);
      setMinRitiro(parseInt(selectedMinute));
    } else {
      setDisableProceed(true);
    }
  }

  function handleHourChange(e) {
    // todo
    // if selected hour != current set showMinutes to minutes array
    let selectedHour = e.target.value;
    if (selectedHour !== '') {
      if (parseInt(selectedHour) > currentHour) { // current hour non viene aggiornato se ho tipo 70 prodotti nella pipeline
        setHideFutureMinutes(false) // lo mostra
        setHideMinutes(true); // nascondi i minuti normali
      } else {
        setHideFutureMinutes(true)
        setHideMinutes(false);
        //setHideMinutes(true);
      }
      setOraRitiro(parseInt(selectedHour));
    } else {
      setHideMinutes(true); // nascondi i minuti normali
      setHideFutureMinutes(true)
      setDisableProceed(true);
    }
  }

  function handleCittaChange(e) {
    let selectedCity = e.target.value;
    if (selectedCity !== '') {
      setCittaConsegna(selectedCity);
    } else {
      setCittaConsegna(undefined);
    }
  }

  function handleViaChange(e) {
    let viaIn = e.target.value;
    if (viaIn !== "" && viaIn !== " ") {
      setVia(viaIn.trim());
    } else {
      setVia(undefined);
    }
  }

  function handleCivicoChange(e) {

    let civicoIn = e.target.value;
    if (civicoIn !== "" && civicoIn !== " ") {
      setCivico(civicoIn);
    } else {
      setCivico(undefined);
    }
  }

  function checkInputFields() {
    if (isDelivery) {
      if (cittaConsegna === undefined || via === undefined || civico === undefined) {
        return false;
      }
    }
    return true
  }

  async function onConfirmClick() {
    if (!checkInputFields()) {
      warn("Compila tutti i campi")
      return
    }
    setDisabled(true);
    setDisableProceed(true);
    generateTimeStamp();

    if (isDelivery) {
      let deliveryObj = {
        citta: cittaConsegna,
        via: via,
        nCivico: civico
      }
      await checkout(deliveryObj);
    } else {
      await checkout();
    }
    resetKey();
    emptyLocalStorage();
  }

  function generateTimeStamp() {
    var d = new Date();
    d.setHours(oraRitiro)
    d.setMinutes(minRitiro)
    d.setSeconds(0)

    timestamp = dayjs(d);
    timestamp = timestamp.unix();
  }

  async function getData() {
    return JSON.parse(sessionStorage.getItem("isDelivery"))
  }

  useEffect(() => {

    let delivery = false;

    const fetchData = async () => {
      const data = await getData();
      if (data === true) {
        delivery = true;
      }
      setDelivery(data);
      getTotalWaitTime(delivery);
      getCities()
    }

    fetchData();

  }, [])

  function setCartTotal(totAmount) {
    setTot(totAmount)
  }

  const checkout = async (deliveryObj) => {
    success()

    let sede = JSON.parse(sessionStorage.getItem("sede_selezionata"));


    await fetch(process.env.REACT_APP_CHECKOUT_ENDPOINT, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ items: order, total: tot, createdAt: timestamp, createdAtFormatted: `${oraRitiro}:${minRitiro}`, sede: sede.negozio, delivery: deliveryObj })
    }).then((response) => {
      return response.json();
    }).then((response) => {
      window.location.assign(response.url);
    })
  }

  const getTotalWaitTime = async (delivery) => {
    let sede

    if (delivery) {
      sede = "sede_principale";
    } else {
      sede = JSON.parse(sessionStorage.getItem("sede_selezionata")).negozio
    }

    await fetch(process.env.REACT_APP_WAIT_TIME_ENDPOINT + `${sede}`, {
      method: "GET",
    }).then((response) => {
      return response.json();
    }).then((response) => {
      getDisabledMinutes(response.waitTime, delivery)
    })
  }

  const getCities = async () => {
    const response = await fetch(process.env.REACT_APP_CITIES_ENDPOINT);
    const cities = await response.json();
    setCities(cities.cities)
  }


  return (

    <div className="bg">
      <ToastContainer />
      <IdleTimerContainer />
      <div className="top">
        <div className="left">
          <Link to="/order" className="text-decoration__none">
            <button className="link button-continue-cart">CONTINUA ORDINE</button>
            <button className="link-cell button-continue-cart">CONTINUA</button>
          </Link>
        </div>
        <div className="right">
          <button className="button-cart" style={isEmpty() ? { display: 'none' } : {}} onClick={() => setConfirm(true)}>CONFERMA ORDINE</button>
          <button className="button-cart-cell button-confirm" style={isEmpty() ? { display: 'none' } : {}} onClick={() => setConfirm(true)}>CONFERMA</button>
        </div>
      </div>
      <div className="product-list-cart">
        <CartProductList totalAmount={setCartTotal} />
      </div>
      <div className="total-cart">
        <span className="total-text-cart" style={isEmpty() ? { display: 'none' } : {}}>Totale: </span>
        <span className="totale-price-cart">{isEmpty() ? "Carrello vuoto" : "€ " + tot}</span>
        <br />
        <button className="bottom-button-cart-cell" style={isEmpty() ? { display: 'none' } : {}} onClick={() => setConfirm(true)}>CONFERMA</button>
        <button className="button-cart mt-20" style={isEmpty() ? { display: 'none' } : {}} onClick={() => setConfirm(true)}>CONFERMA ORDINE</button>
      </div>
      <div className="confirm-cart" style={confirm ? { display: 'block' } : { display: 'none' }}>
        <div className="container-popup-cart" style={isDelivery ? {} : { height: '175px' }}>
          <p className="question-cart">{!navigator.onLine ? "Connessione assente" : "Conferma ordine"}</p>
          <div className="select-hour-cart">
            {isDelivery ?
              <div className="delivery-container">
                <p className="label-delivery" style={{ marginBottom: '10px' }}>Inserisci indirizzo di Consegna</p>
                <div>
                  <span className="label-delivery">Città:</span>
                  <select defaultValue={0}
                    name="Seleziona Città"
                    onChange={e => handleCittaChange(e)}
                    className="select-hours">
                    <option value="" />
                    {cities.map((city, key) => (
                      <option key={key} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
                <label htmlFor="via" className="label-delivery">Via:</label>
                <input type="text" onBlur={e => handleViaChange(e)} id="via" name="via" placeholder="Via Roma" required="true" className="select-city" />
                <br />
                <label htmlFor="nCivico" className="label-delivery">N. Civico:</label>
                <input type="number" onBlur={e => handleCivicoChange(e)} id="nCivico" min="0" placeholder="1" name="nCivico" required="true" className="select-city" style={{ width: '50px' }} />
              </div>
              : ""}
            <h2 className="label-delivery move-left">{isDelivery ? "Seleziona orario di consegna" : "Seleziona orario di ritiro" }</h2>
            <br />
            <select
              defaultValue={0}
              name="Seleziona Ora"
              // on hour select, check if is future hour, if so set normal minutes
              onChange={e => handleHourChange(e)}
              className="select-hours"
            //value={selectedCity}
            >
              <option value="" />
              {showHours.map((hour, key) => (
                <option key={key} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
            <select
              name="Seleziona Minuti x ora corrente"
              style={hideMinutes ? { display: 'none' } : {}}
              onChange={e => handleMinuteChange(e)}
              className="select-hours"
            //value={selectedCity}
            >
              <option value="" />
              {showMinutes.map((minute, key) => (
                <option key={key} value={minute}>
                  {minute}
                </option>
              ))}
            </select>

            <select
              name="Seleziona Minuti x ora futura"
              style={hideFutureMinutes ? { display: 'none' } : {}}
              onChange={e => handleMinuteChange(e)}
              className="select-hours"
            //value={selectedCity}
            >
              <option value="" />
              {minutes2.map((minute, key) => (
                <option key={key} value={minute}>
                  {minute}
                </option>
              ))}
            </select>
          </div>
          <div className={isDelivery ? "container-button-popup-cart container-button-popup-cart-delivery" : "container-button-popup-cart"}>
            <div className="left-popup-cart">
              <button className="button-popup-cart" disabled={disabled} onClick={() => setConfirm(false)} style={!navigator.onLine ? { width: '380px' } : {}}>{!navigator.onLine ? "OK" : "ANNULLA"}</button>
            </div>
            <div className="right-popup-cart" style={!navigator.onLine ? { display: 'none' } : {}}>
              <button className="button-popup-cart" disabled={disableProceed} onClick={async () => { onConfirmClick(); }}>CONFERMA</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}