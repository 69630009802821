import React, {useRef, useState} from "react";
import IdleTimer from "react-idle-timer";
import { Link } from "react-router-dom";
import { resetKey, emptyLocalStorage } from "../javascript/localStorageFunctions";


function IdleTimerContainer() {
  const idleTimerRef = useRef(null)
  const [open, setOpen] = useState(false);

  const onIdle = () => {
    setOpen(true)
  }

  return(
    <div>
      <IdleTimer ref={idleTimerRef} timeout={5 * 6000} onIdle={ onIdle }></IdleTimer>
      <div className="confirm-cart" style={ open === false ? { display: 'none' } : { display: 'block' } }>
        <div className="container-popup-cart" style={{height:'100px'}}>
          <p className="question-cart">Sei ancora qui?</p>
          <div className="container-button-popup-cart" style={{marginTop:'20px'}}>
            <div className="left-popup-cart">
              <Link to="/">
                <button className="button-popup-cart" onClick={ () => { resetKey(); emptyLocalStorage()} }>NO</button>
              </Link>
            </div>
            <div className="right-popup-cart">
              <button className="button-popup-cart" onClick={ () => setOpen(false) }>SI</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IdleTimerContainer