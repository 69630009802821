import { React } from "react"
import { MenuListArray } from "./../javascript/data"
import { Product } from "./Product"
import "./../css/productList.css"

export const MenuList = () => {
  return (
    <div className="position">
      { MenuListArray.map((item, index) => (
        <Product item={ item } key={ index } />
      ))}
    </div>
  )
}
