import { React, useState } from "react"
import { useParams } from "react-router-dom"
import { panini, piadine, taco, piatti, menu_pollo, menu_tavuk, menu_vitello, contorni, cevapcici, bibite, altro } from "./../javascript/data"
import { IngredientsList } from "./IngredientsList"
import { ExtraIngredientsList } from "./ExtraIngredientsList"
import "./../css/singleType1.css"
import "./../css/ingredientsList.css"
import { TacoIngredients } from "./TacoIngredients"

export let productInfo = [];
export let tipo 
export let prodottoType
export let hasKetchup
export let hasMaionese

export const SingleType1 = () => {
  let { type, product } = useParams();
  const [ketchup, setKetchup] = useState(false)
  const [maionese, setMaionese] = useState(false)

  hasKetchup = ketchup
  hasMaionese = maionese
  tipo = type
  prodottoType = product
  let selection;
  let displayIngredients;
  let displaySalse = { display: 'none' };
  let cssImage = "img-type1"

  function color(boolean) {
    return boolean ? { color: 'lightgreen' } : { textDecoration: 'line-through', color: 'red' }
  }

  if (type === "panini" && product === "hot-dog") {
    selection = panini
    displayIngredients = { display: 'none' }
    displaySalse = { display: 'block' }
  } else if (type === "panini") {
    selection = panini
  } else if (type === "piadine") {
    selection = piadine
  } else if( type === "taco"){
    selection = taco;
  } else if (type === "piatti") {
    selection = piatti
  } else if (type === "pollo") {
    selection = menu_pollo
    cssImage = "img-type2"
  } else if (type === "misto") {
    selection = menu_tavuk
    cssImage = "img-type2"
  } else if (type === "vitello") {
    selection = menu_vitello
    cssImage = "img-type2"
  } else if (product === "chicken-nuggets-e-patate-fritte") {
    selection = contorni
    displayIngredients = { display: 'none' }
    displaySalse = { display: 'block' }
  } else if (type === "contorni" && product === "patatine-normali") {
    selection = contorni
    displayIngredients = { display: 'none' }
    displaySalse = { display: 'block' }
  } else if (type === "contorni" && product === "patatine-maxi") {
    selection = contorni
    displayIngredients = { display: 'none' }
    displaySalse = { display: 'block' }
  } else if (type === "contorni") {
    selection = contorni
    displayIngredients = { display: 'none' }
  } else if (type === "cevapcici") {
    selection = cevapcici
  } else if (type === "bibite") {
    selection = bibite
    displayIngredients = { display: 'none' }
  } else if (type === "altro") {
    selection = altro
    displayIngredients = { display: 'none' }
  } 

  { selection.map((item) => {
    if (item.product === product) {
      productInfo = item
    }
    return productInfo
  })}

  return (
    <>
      <div className="img-container-type1">
        <img src={ productInfo.image } alt={ productInfo.alt } className={ cssImage } />
      </div>
      <div className="bottom-type1">
        <div className="left-type1" style={ displayIngredients }>
          {
            type === "taco" ? <TacoIngredients/> :
            <>
              <p className="title-left-type1">INGREDIENTI: </p>
              <IngredientsList />
              <div className="button-inline-type1">
                <ExtraIngredientsList />
              </div>
            </> 
          }
        </div>
        <div className="right-type1">
          <p className="name-right-type1">{ productInfo.name }</p>
          <p className="price-right-type1">€ { productInfo.price }</p>
          <p className="description-right-type1">{ productInfo.desc }</p>
          <div style={ displaySalse }>
            <div className="salse-title-type1">SALSE</div>
            <div>
              <label className="select-container" style={{ fontSize: '24px', marginTop: '20px' }}>
                <span style={ color(ketchup) }>Ketchup</span>
                <input type="checkbox" checked={ ketchup } onClick={ () => setKetchup(!ketchup) } style={ color(ketchup) } onChange={ () => {} }/>
                <span className="select-checkmark"></span>
              </label>
              <label className="select-container" style={{ fontSize: '24px', marginTop: '20px' }}>
                <span style={ color(maionese) }>Maionese</span>
                <input type="checkbox" checked={ maionese } onClick={ () => setMaionese(!maionese) } style={ color(maionese) } onChange={ () => {} }/>
                <span className="select-checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
