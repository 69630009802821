import { React } from "react"
import { Link } from "react-router-dom"
import "./../css/product.css"

export const Product = ({ item }) => {
  return (
    <Link to={item.type === "menu" ? "/menutype" : "/order/" + item.type} className="text-decoration__none">
      <div className="container">
        <div className="up">
          <img src={item.image} alt={item.alt} className="img" />
        </div>
        <div className="down">
          <p>{item.name}</p>
        </div>
      </div>
    </Link>
  )
} 