import { React, useState } from "react"
import "./../css/ingredientsList.css"

export let ingredients 

export const IngredientsList = () => {
  const [lattuga, setLattuga] = useState(true);
  const [radicchio, setRadicchio] = useState(true);
  const [pomodoro, setPomodoro] = useState(true);
  const [cipolla, setCipolla] = useState(true);
  const [cappuccio, setCappuccio] = useState(true);
  const [peperoni, setPeperoni] = useState(true);
  const [aglio, setAglio] = useState(true);
  const [piccante, setPiccante] = useState(true);

  ingredients = [lattuga, radicchio, pomodoro, cipolla, cappuccio, peperoni, aglio, piccante];

  function color(boolean) {
    return boolean ? { color: 'lightgreen' } : { textDecoration: 'line-through', color: 'red' }
  }

  return (
    <div className="list-ingredients-list">
      <label className="select-container" style={{ marginTop: '10px' }}>
        <span style={ color(lattuga) }>Lattuga</span>
        <input type="checkbox" checked={ lattuga } onClick={ () => setLattuga(!lattuga) } style={ color(lattuga) } onChange={ () => {} }/>
        <span className="select-checkmark"></span>
      </label>
      <label className="select-container">
        <span style={ color(radicchio) }>Radicchio</span>
        <input type="checkbox" checked={ radicchio } onClick={ () => setRadicchio(!radicchio) } style={ color(radicchio) } onChange={ () => {} }/>
        <span className="select-checkmark"></span>
      </label>
      <label className="select-container">
        <span style={ color(pomodoro) }>Pomodoro</span>
        <input type="checkbox" checked={ pomodoro } onClick={ () => setPomodoro(!pomodoro) } style={ color(pomodoro) } onChange={ () => {} }/>
        <span className="select-checkmark"></span>
      </label>
      <label className="select-container">
        <span style={ color(cipolla) }>Cipolla</span>
        <input type="checkbox" checked={ cipolla } onClick={ () => setCipolla(!cipolla) } style={ color(cipolla) } onChange={ () => {} }/>
        <span className="select-checkmark"></span>
      </label>
      <label className="select-container">
        <span style={ color(cappuccio) }>Cappuccio</span>
        <input type="checkbox" checked={ cappuccio } onClick={ () => setCappuccio(!cappuccio) } style={ color(cappuccio) } onChange={ () => {} }/>
        <span className="select-checkmark"></span>
      </label>
      <label className="select-container">
        <span style={ color(peperoni) }>Salsa yogurt ai peperoni</span>
        <input type="checkbox" checked={ peperoni } onClick={ () => setPeperoni(!peperoni) } style={ color(peperoni) } onChange={ () => {} }/>
        <span className="select-checkmark"></span>
      </label>
      <label className="select-container">
        <span style={ color(aglio) }>Salsa yogurt all'aglio</span>
        <input type="checkbox" checked={ aglio } onClick={ () => setAglio(!aglio) } style={ color(aglio) } onChange={ () => {} }/>
        <span className="select-checkmark"></span>
      </label>
      <label className="select-container">
        <span style={ color(piccante) }>Salsa piccante</span>
        <input type="checkbox" checked={ piccante } onClick={ () => setPiccante(!piccante) } style={ color(piccante) } onChange={ () => {} }/>
        <span className="select-checkmark"></span>
      </label>
    </div>
  )
}