import { React, useState, useEffect } from "react"
import "./../css/cartProduct.css"

export const CartProduct = ({ item, deleteElem, updateTOT }) => {
  let controllo = item.tipo
  let latt, rad, pom, cip, capp, sal_pep, sal_agl, sal_pic, patE, verE, formE, piccE, salE, ketE, maioE, ketchup, maionese;
  let cssDisplay;
  let variableSausages = item.prodotto === "Patatine fritte - Normali" || item.prodotto === "Patatine fritte - Maxi" || item.prodotto === "Chicken Nuggets + Patate Fritte" || item.prodotto === "Hot Dog" ? { marginTop: "15px" } : { display: 'none' }

  if (item.prodotto === "Hot Dog") {
    ketchup = item.ingredients.ketchup
    maionese = item.ingredients.maionese
    cssDisplay = { display: 'none' }
  }
  else if (item.tipo === "taco"){
    cssDisplay = { display: 'none' }
  } 
  else if (controllo === "panini" || controllo === "piadine" || controllo === "piatti" || controllo === "cevapcici" || controllo === "menu") {
    latt = item.ingredients.lattuga
    rad = item.ingredients.radicchio
    pom = item.ingredients.pomodoro
    cip = item.ingredients.cipolla
    capp = item.ingredients.cappuccio
    sal_pep = item.ingredients.salsa_peperoni
    sal_agl = item.ingredients.salsa_aglio
    sal_pic = item.ingredients.salsa_piccante
    patE = item.extra.patate
    verE = item.extra.verdura
    formE = item.extra.formaggio
    piccE = item.extra.piccante
    salE = item.extra.salse
    ketE = item.extra.ketchup
    maioE = item.extra.maionese
  } else if (item.prodotto === "Patatine fritte - Normali" || item.prodotto === "Patatine fritte - Maxi" || item.prodotto === "Chicken Nuggets + Patate Fritte") {
    ketchup = item.ingredients.ketchup
    maionese = item.ingredients.maionese
    cssDisplay = { display: 'none' }
  } else if (controllo === "bibite" || controllo === "altro" || controllo === "contorni") {
    cssDisplay = { display: 'none' }
  }

  function color(boolean) {
    return boolean ? { color: 'lightgreen' } : { textDecoration: 'line-through', color: 'red' }
  }

  let ingredientsName = ["Lattuga", "Radicchio", "Pomodoro", "Cipolla", "Cappuccio", "Salsa peperoni", "Salsa aglio", "Salsa piccante"];
  let extraName = ["Patate fritte + €1", "Verdura + €1", "Formaggio + €1", "Piccante", "Salse", "Ketchup", "Maionese"];
  let ingredients = [latt, rad, pom, cip, capp, sal_pep, sal_agl, sal_pic];
  let extra = [patE, verE, formE, piccE, salE, ketE, maioE];
  let sauces = [ketchup, maionese]

  function check() {
    if (controllo === "menu")
      return { marginTop: '10px' }
    else
      return { display: 'none' }
  }

  return (
    <div className="box-cart-product">
      <div className="information-cart-product">
        <p className="name-cart-product">{item.prodotto}</p>
        <p className="price-cart-product">€ {item.price}</p>
        <div style={{ marginTop: "15px" }}>
          <span className="quantity-cart-product">Quantità: </span>
          <span className="quantity-cp">{item.quantita}</span>
        </div>
      </div>
      <div className="ingredients-container-cart-prduct" style={cssDisplay}>
        <div className="ingredients-left-cart-product">
          <p className="type-cart-product">Ingredienti:</p>
          <ul>
            {ingredientsName.map((item, index) => (
              <div>
                {ingredients[index] ?
                  <li key={index}>
                    <button className="ingredients-selection-cart-product" style={color(ingredients[index])}>{item}</button>
                  </li>
                  : ""}
              </div>
            ))}
          </ul>
        </div>
        <div className="extra-right-cart-product">
          <p className="type-cart-product">Extra:</p>
          <ul>
            {extraName.map((item, index) => (
              <div>
                {extra[index] ?
                  <li key={index}>
                    <button className="extra-selection-cart-product" style={color(extra[index])}>{item}</button>
                  </li>
                  : ""}
              </div>
            ))}
          </ul>
        </div>
      </div>
       {
        item.tipo === "taco" ?
        <>
          <br />
          <h2 className="type-cart-product space-right-cart-product">Proteina: <span style={{ color: 'lightgreen' }}>{item.proteina}</span></h2>
          <h2 className="type-cart-product space-right-cart-product">Salsa: <span style={{ color: 'lightgreen' }}>{item.salsa}</span></h2>
          {/* <h2 className="type-cart-product space-right-cart-product">Contorno: <span style={{ color: 'lightgreen' }}>{item.fritto}</span></h2> */}
        </>
        :
        <></>
      }
      <div className="align-item-cart-product" style={variableSausages}>
        <div className="container-cell">
          <span className="type-cart-product space-right-cart-product">Salse:</span>
          <ul>
            <li><button className="space-right-cart-product button-drink-cart-product sauces" style={color(sauces[0])}>Ketchup</button></li>
            <li><button className="space-right-cart-product button-drink-cart-product sauces" style={color(sauces[1])}>Maionese</button></li>
          </ul>
        </div>
        <div className="pc-container">
          <span className="type-cart-product space-right-cart-product">Salse:</span>
          <button className="space-right-cart-product button-drink-cart-product sauces" style={color(sauces[0])}>Ketchup</button>
          <button className="space-right-cart-product button-drink-cart-product sauces" style={color(sauces[1])}>Maionese</button>
        </div>
      </div>
      <span className="type-cart-product space-cart-product" style={check()}>Bibita: </span>
      <button className="button-drink-cart-product" style={check()} >{item.bibita}</button>
      <br />
      <span className="type-cart-product space-cart-product" style={check()}>Contorni: </span>
      <button className="button-drink-cart-product" style={check()} >{item.contorno}</button>
      <div className="delete-cart-product">
        <button className="delete-button-cart-product" onClick={() => { deleteElem(item.index); updateTOT() }}>ELIMINA</button>
      </div>
    </div>
  )
}