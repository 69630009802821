import React from 'react'
import '../css/error.css'
import { Link } from 'react-router-dom'

import logo from '../images/logo.png'

export const Error = () => {
  return (
    <div className='bg'>
      <div className="logo">
        <img src={ logo } alt="logo.png" className="logo-image" />
      </div>
      <div className="text-container">
        <p className='text-error'>Oops...! Qualcosa è andato storto.</p>
        <p className='text-error' style={{marginTop:'30px'}}>Perfavore riprova</p>
      </div>
      <Link to='/'>
        <button className='button-error'>Torna Indietro</button>
      </Link>
    </div>
  )
}