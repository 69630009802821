import { React, useEffect } from "react"
import "./../css/selectDrinks.css"
import { contorniMenu } from "./../javascript/data"
import { genearteKey } from "../javascript/localStorageFunctions"
import { Link } from "react-router-dom"

export let sContorno

export function resetDrink() {
  sContorno = undefined
}
function selectedContorno(cont) {
  sContorno = cont
}

let unavaibleContorni = []

export const Contorni = ({ add, setShowDrinks, setContorni }) => {


  useEffect(() => {
    const fetchData = async () => {
      let unavaibleProducts = JSON.parse(sessionStorage.getItem("unavaibleProducts"))

      debugger;

      contorniMenu.forEach(contorno => {
        if (unavaibleProducts.hasOwnProperty(contorno.product)) {
          let currentItem = unavaibleProducts[contorno.product];
          if (currentItem === false) {
            unavaibleContorni.push(contorno.product)
          }
        }
      });

    }

    fetchData();
  }, []);

  return (
    <div>
      <div className="box-drinks">
        <div className="container-drinks">
          <div className="title-drinks">CONTORNI</div>
          <p className="close-drinks" onClick={() => { setShowDrinks(true); setContorni(false); resetDrink() }}>✕</p>
          <div className="fixed-drinks">
            <div className="position-drinks">
              {contorniMenu.map((item,index) => (
                <div key={index}>
                    {
                      unavaibleContorni.includes(item.product) ? 
                        <div style={{ opacity: "0.3" }} className="container-drinks-single">
                            <button className="button-reset-drinks-single">
                              <div className="up">
                                <img src={item.image} alt={item.alt} className="img" />
                              </div>
                              <div className="down"> 
                                <p className="font-size-drinks">Prodotto esaurito</p>
                              </div>
                            </button>
                        </div>
                      :
                      <Link to="/order" className="text-decoration__none">
                        <div className="container-drinks-single">
                            <button className="button-reset-drinks-single" onClick={() => { selectedContorno(item.name); add(); genearteKey(); }} >
                              <div className="up">
                                <img src={item.image} alt={item.alt} className="img" />
                              </div>
                              <div className="down"> 
                                <p className="font-size-drinks">{item.name}</p>
                              </div>
                            </button>
                        </div>
                      </Link>
                    }
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}