import { React, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import "./../css/specificProduct.css"

export const SpecificProduct = ({ item }) => {

  // check if product is available...
  // if not disable it

  //const [unavaibleProducts, setUnavaibleProducts] = useState();
  const [disable, setDisabled] = useState();


  let { type } = useParams();
  let cssBox, cssImage, cssName;

  if (type === "vitello" || type === "pollo" || type === "misto") {
    cssBox = "container-specific-2"
    cssImage = "image-specific-2"
    cssName = "name-specific-2"
  } else {
    cssBox = "container-specific"
    cssImage = "image-specific"
    cssName = "name-specific"
  }


  useEffect(() => {

    const fetchData = async () => {

      let unavaibleProducts = await JSON.parse(sessionStorage.getItem("unavaibleProducts"))
      if (unavaibleProducts.hasOwnProperty(item.product)) {
        let currentItem = unavaibleProducts[item.product];
        if (currentItem === false) {
          setDisabled(true);
        }
      }
    }

    fetchData();

  }, []);

  
  return (
    <div style={disable ? {opacity: "0.4"} : {opacity: "1"}}className="text-decoration__none">
      {
        disable ?
        <div className={ cssBox }>
          <div>
            <img  src={ item.image } alt={ item.alt } className={ cssImage } />
          </div>
          {
            disable ?
            <p className={ cssName }>Prodotto esaurito</p>  :
            <div>
              <p className={ cssName }>{ item.name }</p>
              <p className="price-specific">€ { item.price }</p>
            </div>
          }
        </div>
        :
        <Link to={ "/order/" + type + "/" + item.product } className="text-decoration__none">
          <div className={ cssBox }>
            <div>
              <img src={ item.image } alt={ item.alt } className={ cssImage } />
            </div>
            <div>
              <p className={ cssName }>{ item.name }</p>
              <p className="price-specific">€ { item.price }</p>
            </div>
          </div>
        </Link>
      }
    </div>
  )
}