import { toast } from 'react-toastify';

export const warn = (str) => {
  toast.warn(str, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

/*
export const info = (str) => {
  toast.info(str, {
    // Configuration options for info alerts
  });
};

export const success = (str) => {
  toast.success(str, {
    // Configuration options for success alerts
  });
};

// Other alert handling functions...
*/