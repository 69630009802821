import { React } from "react"
import { Link } from "react-router-dom"
import { SpecificProductList } from "../components/SpecificProductList"
import "./../css/order.css"
import IdleTimerContainer from "../components/IdleTimerContainer"
import { ArrowLeftOutlined, ShoppingCartOutlined } from "@ant-design/icons"

export const OrderProduct = () => {
  return (
    <div className="bg">
      <div className="top">
        <div className="left">
          <IdleTimerContainer/>
          <Link to="/order" className="text-decoration__none">
            <ArrowLeftOutlined className="icon-size"/>
          </Link>
        </div>
        <div className="right">
          <Link to="/cart" className="text-decoration__none">
            <ShoppingCartOutlined className="icon-size"/>
          </Link>
        </div>
      </div>
      <SpecificProductList />
    </div>
  )
}