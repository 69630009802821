import { React, useEffect, useState } from "react"
import logo from "./../images/logo.png"
import negozio1 from "./../images/negozio1.jpeg"
import negozio2 from "./../images/negozio2.jpeg"
import { ToastContainer, toast } from 'react-toastify';

import "./../css/negozi.css"

export const Negozi = () => {

  const [data, setData] = useState([])

  async function getData() {
    const response = await fetch(process.env.REACT_APP_SHOPS_ENDPOINT);
    const json = await response.json();

    return json.negozi;

  }

  useEffect(() => {

    sessionStorage.clear();

    const fetchData = async () => {
      const data = await getData();
      setData(data);
    }

    fetchData();

  }, []);

  const warn = (str) => toast.warn(str, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const imageClick = (index) => {
    const negozioSelezionato = data[index];

    if (index === 0) {

      if (!negozioSelezionato.sede_principale.isAvailable) {
        warn(negozioSelezionato.sede_principale.motivoChiusura);
        return;
      }

      setNegozioData(negozioSelezionato);

      window.location.assign("/homepage");
    } else {
      if (!negozioSelezionato.sede_centro.isAvailable) {
        warn(negozioSelezionato.sede_centro.motivoChiusura);
        return;
      }

      setNegozioData(negozioSelezionato);

      window.location.assign("/homepage");

    }
  }

  function setNegozioData(negozioSelezionato) {
    let neg = Object.keys(negozioSelezionato).toString()
    sessionStorage.setItem("sede_selezionata", JSON.stringify({
      negozio: Object.keys(negozioSelezionato).toString(),
      delivery: negozioSelezionato[neg].delivery,
      motivoChiusura: negozioSelezionato[neg].motivoChiusura,
      isAvailable: negozioSelezionato[neg].isAvailable,
      indirizzo: negozioSelezionato[neg].indirizzo
    }));
  }

  return (
    <div className="bg negozi">
      <ToastContainer />
      <div className="logo">
        <img src={logo} alt="logo.png" className="logo-image" />
      </div>
      <div className="image-container">
        <h1 className="select-negozi">Seleziona negozio:</h1>
        <div className="align-images">
          <img src={negozio1} alt="Negozio 1" onClick={() => imageClick(0)} />
        </div>
        <h3 className="negozio-address">VIA RIZZERA 399, 31029 TV</h3>
        {/* <div className="align-images">
          <img src={negozio2} alt="Negozio 2" onClick={() => imageClick(1)} />
        </div>
        <h3 className="negozio-address">VIALE VITTORIO EMANUELE II 136, 31029 TV</h3>
      */}
      </div> 
    </div>
  )
}