import { React } from "react"
import { Link } from "react-router-dom"
import "./../css/order.css"
import { MenuList } from "./../components/MenuList"
import IdleTimerContainer from "../components/IdleTimerContainer"
import { ArrowLeftOutlined, ShoppingCartOutlined } from "@ant-design/icons"

export const MenuType = () => {
  return (
    <div className='bg'>
      <div className="top">
        <div className="left">
          <IdleTimerContainer/>
            <Link to="/order" className="text-decoration__none">
              <ArrowLeftOutlined className="icon-size"/>
            </Link>
        </div>
        <div className="right">
          <Link to="/cart" className="text-decoration__none">
            <ShoppingCartOutlined className="icon-size"/>
          </Link>
          <p className="information-space">space</p>
        </div>
      </div>
      <p className="information">Se torni indietro il tuo ordine verrà cancellato!</p>
      <MenuList />
    </div>
  )
}
