import { React, useState } from "react"
import "./../css/extraIngredientsList.css"
import "./../css/ingredientsList.css"

export let extra

export const ExtraIngredientsList = () => {
  const [patate, setPatate] = useState(false);
  const [verdura, setVerdura] = useState(false);
  const [formaggio, setFormaggio] = useState(false);
  const [piccante, setPiccante] = useState(false);
  const [salse, setSalse] = useState(false);
  const [ketchup, setKetchup] = useState(false);
  const [maionese, setMaionese] = useState(false);
  const [showExtra, setShowExtra] = useState(false);

  extra = [patate, verdura, formaggio, piccante, salse, ketchup, maionese];

  function color(boolean) {
    return boolean ? { color: 'lightgreen' } : {color: 'red' }
  }

  return (
    <div>
      <button className="button-extra" onClick={ () => setShowExtra(!showExtra) }>EXTRA</button>
      <div className="box-extra" style={ showExtra ? { display: 'block' } : { display: 'none' } }>
        <div className="container-extra">
          {/* <button className="button-extra" onClick={ () => setShowExtra(!showExtra) }>Chiudi</button> */}
          <div className="title-extra">SELEZIONA EXTRA</div>
          <p className="close-extra" onClick={ () => setShowExtra(false) }>✕</p>
          <div className="ingredients-extra">
            <label className="select-container" style={{ fontSize: '30px', marginTop: '25px' }}>
              <span style={ color(patate) } className="font-size-extra">Patate fritte (+1 euro)</span>
              <input type="checkbox" checked={ patate } onClick={ () => setPatate(!patate) } style={ color(patate) } onChange={ () => {} }/>
              <span className="select-checkmark"></span>
            </label>
            <label className="select-container" style={{ fontSize: '30px', marginTop: '25px' }}>
              <span style={ color(verdura) } className="font-size-extra">Verdura (+1 euro)</span>
              <input type="checkbox" checked={ verdura } onClick={ () => setVerdura(!verdura) } style={ color(verdura) } onChange={ () => {} }/>
              <span className="select-checkmark"></span>
            </label>
            <label className="select-container" style={{ fontSize: '30px', marginTop: '25px' }}>
              <span style={ color(formaggio) } className="font-size-extra">Formaggio (+1 euro)</span>
              <input type="checkbox" checked={ formaggio } onClick={ () => setFormaggio(!formaggio) } style={ color(formaggio) } onChange={ () => {} }/>
              <span className="select-checkmark"></span>
            </label>
            <label className="select-container" style={{ fontSize: '30px', marginTop: '25px' }}>
              <span style={ color(piccante) } className="font-size-extra">Piccante</span>
              <input type="checkbox" checked={ piccante } onClick={ () => setPiccante(!piccante) } style={ color(piccante) } onChange={ () => {} }/>
              <span className="select-checkmark"></span>
            </label>
            <label className="select-container" style={{ fontSize: '30px', marginTop: '25px' }}>
              <span style={ color(salse) } className="font-size-extra">Salse</span>
              <input type="checkbox" checked={ salse } onClick={ () => setSalse(!salse) } style={ color(salse) } onChange={ () => {} }/>
              <span className="select-checkmark"></span>
            </label>
            <label className="select-container" style={{ fontSize: '30px', marginTop: '25px' }}>
              <span style={ color(ketchup) } className="font-size-extra">Ketchup</span>
              <input type="checkbox" checked={ ketchup } onClick={ () => setKetchup(!ketchup) } style={ color(ketchup) } onChange={ () => {} }/>
              <span className="select-checkmark"></span>
            </label>
            <label className="select-container" style={{ fontSize: '30px', marginTop: '25px' }}>
              <span style={ color(maionese) } className="font-size-extra">Maionese</span>
              <input type="checkbox" checked={ maionese } onClick={ () => setMaionese(!maionese) } style={ color(maionese) } onChange={ () => {} }/>
              <span className="select-checkmark"></span>
            </label>
          </div>
          <div className="button-container-extra">
            <button className="confirm-button-extra" onClick={ () => setShowExtra(!showExtra) }>CONFERMA</button>
          </div>
        </div>
      </div>
    </div>
  )
}
