import { React } from "react"
import { OrderList } from "./../javascript/data"
import { Product } from "./Product"
import "./../css/productList.css"

export const ProductList = () => {
  return (
    <div className="position">
      {OrderList.map((item, index) => (
        <div key={index}>
          <Product item={item} />
        </div>
      ))}
    </div>
  )
}