import { React, useState, useEffect } from "react"
import "./../css/selectDrinks.css"
import { bibite } from "./../javascript/data"
import { genearteKey } from "../javascript/localStorageFunctions"
import { Link } from "react-router-dom"

import { Contorni } from "./Contorni"

export let sDrink

export function resetDrink() {
  sDrink = undefined
}

let outOfStockDrinks = [];

export const SelectDrinks = ({ add }) => {
  const [showDrinks, setShowDrinks] = useState(false);
  const [contorni, setContorni] = useState(false)
  //const [disable, setDisabled] = useState([])

  function selectedDrink(dr) {
    sDrink = dr
  }

  useEffect(() => {

    const fetchData = async () => {
      let unavaibleProducts = JSON.parse(sessionStorage.getItem("unavaibleProducts"))

      bibite.forEach(bibita => {
        if (unavaibleProducts.hasOwnProperty(bibita.product)) {
          let currentItem = unavaibleProducts[bibita.product];
          if (currentItem === false) {
            outOfStockDrinks.push(bibita.product)
          }
        }
      });

    }

    fetchData();

  }, []);

  return (
    <div>
      <button className="addcart-single" onClick={() => setShowDrinks(!showDrinks)}>AGGIUNGI AL CARRELLO</button>
      <div className="box-drinks" style={showDrinks ? { display: 'block' } : { display: 'none' }}>
        <div className="container-drinks">
          <div className="title-drinks">BIBITE</div>
          <p className="close-drinks" onClick={() => setShowDrinks(false)}>✕</p>
          <div className="fixed-drinks">
            <div className="position-drinks">
              {bibite.map((item, index) => (
                <div key={index}>
                  {outOfStockDrinks.includes(item.product) ?
                    <div style={{ opacity: "0.3" }} className="container-drinks-single">
                      <button className="button-reset-drinks-single">
                        <div className="up">
                          <img src={item.image} alt={item.alt} className="img" />
                        </div>
                        <div className="down">
                          <p className="font-size-drinks">Prodotto esaurito</p>
                        </div>
                      </button>
                    </div>
                    :
                    <div className="container-drinks-single" onClick={() => { setContorni(true); setShowDrinks(false) }}>
                      <button className="button-reset-drinks-single" onClick={() => { setShowDrinks(!showDrinks); selectedDrink(item.name) }}>
                        <div className="up">
                          <img src={item.image} alt={item.alt} className="img" />
                        </div>
                        <div className="down">
                          <p className="font-size-drinks">{(item.name.includes("ml") && !item.name.includes("Acqua")) || item.name.includes("RedBull") ? item.name.concat("+ €0.50") : item.name}</p>
                        </div>
                      </button>
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={contorni ? {} : { display: 'none' }}>
        <Contorni setShowDrinks={setShowDrinks} setContorni={setContorni} add={add} />
      </div>
    </div>
  )
}